import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_0plzz33', 'template_h9x4e9q', e.target, 'xd0H4R4z5XCxF6oIf'
      )
      .then(
        (result) => {
          console.log(result.text)
          setSuccessMessage('Messaggio inviato correttamente, grazie per averci contattato, risponderemo prima possibie.')
          clearState()
        },
        (error) => {
          setErrorMessage('Si è verificato un errore, aggiorna la pagina e riprova per favore.')
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Per favore compila il modulo sottostante per inviarci una e-mail e ti risponderemo il prima possibile.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nome'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Messaggio'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Invia Messaggio
                </button>
                <p className='ok-msg'>{successMessage !== '' ? successMessage : ''}</p>
                <p className='err-msg'>{errorMessage !== '' ? errorMessage : ''}</p>
              </form>
            </div>
          </div>
          {/*
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contattaci</h3>
              {/*<p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone1 : 'loading'}
              </p>
              <p>
                {props.data ? props.data.phone2 : 'loading'}
              </p>
            </div>
            {/*<div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          */}
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.linkedin : '/'}>
                      <i className='fa fa-linkedin'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            2023 &copy; On Time è un prodotto <a href="https://dingegno.com">DINGEGNO SRLS</a>
          </p>
          <div>
            <a href={props.data ? props.data.appstore : '/'} target='_blank'>
              <img src="img/app-store.png" className='store-icon store-ios' alt="Get On Time on the App Store"/>
            </a>
            <a href={props.data ? props.data.playstore : '/'} target='_blank'>
              <img src="img/play-store.png" className='store-icon store-android' alt="Get On Time on the App Store"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
